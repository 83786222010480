import { increment } from "./metrics/graphene";
const OPEN_SNAPCHAT_PREFIX = "open_snapchat";
const OPEN_SNAPCHAT_SUCCESS = `${OPEN_SNAPCHAT_PREFIX}.request`;
const OPEN_SNAPCHAT_DISMISSED = `${OPEN_SNAPCHAT_PREFIX}.dismissed`;
export function openSnapchat() {
    // Open lens in Snapchat
    const customUrl = "snapchat://unlock/?type=SNAPCODE&uuid=5b0cd91504984feea042faceeec78e78&metadata=01";
    window.location.href = customUrl;
    // Set a timer to detect if the app opened successfully
    const timeout = setTimeout(function () {
        increment(OPEN_SNAPCHAT_DISMISSED);
    }, 2000);
    // App opened successfully
    window.addEventListener("pagehide", function () {
        increment(OPEN_SNAPCHAT_SUCCESS);
    });
}
