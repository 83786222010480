import {
    CameraKit,
    CameraKitBootstrapConfiguration,
    CameraKitConfiguration,
    CameraKitSession,
    Injectable,
    Lens,
    LensPerformanceMeasurement,
    bootstrapCameraKit,
    configurationToken,
    getPlatformInfo,
  } from '@snap/camera-kit';
  import {
    ReactNode,
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
  } from 'react';
import Loading from '../components/Loading/Loading';
import { increment } from '../utils/metrics/graphene';
  
  const MINIMUM_LOADING_TIME_MS = 1500;
  const LOADING_FADE_TIME_MS = 500;
  const LENS_EXECUTION_ERROR = "camkit_error.lens_execution_error";
  const BOOTSTRAP_ERROR = "camkit_error.bootstrap_error";
  const PLATFORM_NOT_SUPPORTED_ERROR = "camkit_error.platform_not_supported_error";
  
  export interface CameraKitVersions {
    sdk?: string;
    lc?: string;
  }
  
  interface CameraKitState {
    cameraKit: CameraKit;
    session: CameraKitSession;
    lenses: Lens[];
    measurement: LensPerformanceMeasurement;
    versions: CameraKitVersions;
    canvas: HTMLCanvasElement;
  }
  
  export const CameraKitMasterContext = createContext<CameraKitState | undefined>(
    undefined
  );
  
  export interface CameraKitProps {
    apiToken: string;
    lensGroupId: string;
    shouldUseWorker?: boolean;
    children?: ReactNode;
  }
  
  export enum LoadingState {
    LOADING = 'loading',
    PENDING_COMPLETION = 'pending-complete',
    FINISHED = 'finished',
  }
  
  export const CameraKitContext: React.FC<CameraKitProps> = ({
    apiToken,
    lensGroupId,
    shouldUseWorker,
    children
  }) => {
    const isMounted = useRef<boolean>(false);
    const [cameraKit, setCameraKit] = useState<CameraKit>();
    const [session, setSession] = useState<CameraKitSession | null>(null);
    const [lenses, setLenses] = useState<Lens[]>([]);
    const [measurement, setMeasurement] = useState<LensPerformanceMeasurement>();
    const [versions, setVersions] = useState<CameraKitVersions>({});
    const [loadingState, setLoadingState] = useState<LoadingState>(
      LoadingState.LOADING
    );
  
    const initializeCameraKit = useCallback(async () => {
      if (isMounted.current) return;
      isMounted.current = true;
  
      const configuration = Injectable(
        configurationToken,
        [configurationToken] as const,
        (config: CameraKitConfiguration) => ({
          ...config,
          // NOTE: tweak that if needed
          // Currently by default worker is enabled for all platforms except of iOS
          // due to a bug: https://jira.sc-corp.net/browse/CAMKIT-5985
          // shouldUseWorker,
        })
      );
  
      const config: CameraKitBootstrapConfiguration = {
        apiToken,
        lensCoreOverrideUrls: {
          wasm: 'https://snap-ck-ms-teams.s3.amazonaws.com/lc/LensCoreWebAssembly.wasm',
          js: 'https://snap-ck-ms-teams.s3.amazonaws.com/lc/LensCoreWebAssembly.js',
        },
      };
  
      const cameraKit = await bootstrapCameraKit(config, (container) =>
        container.provides(configuration)
      );
  
      const session = await cameraKit.createSession();

      session.events.addEventListener("error", ({ detail }) => {
        if (detail.error.name === "LensExecutionError") {
          // TODO Do something with the view e.g. message in center and dark overlay
          // with text "The Lens you selected encountered an error. Please choose a different Lens.");
          increment(LENS_EXECUTION_ERROR);
        }
      });
  
      session.play();
  
      const { lenses } = await cameraKit.lensRepository.loadLensGroups([
        lensGroupId,
      ]);
  
      let lens = lenses[0];
  
      if (lens) {
        session.applyLens(lens);
      }
  
      const measurement = session.metrics.beginMeasurement();
      const platformInfo = getPlatformInfo();

      setCameraKit(cameraKit);
      setSession(session);
      setLenses(lenses);
      setMeasurement(measurement);
      setVersions({
        sdk: platformInfo.sdkShortVersion,
        lc: `${platformInfo.lensCore.version}/${platformInfo.lensCore.buildNumber}`,
      });
    }, [
      apiToken,
      lensGroupId,
      shouldUseWorker
    ]);
  
    useEffect(() => {
      const initialize = async () => {
        try {
          initializeCameraKit();
        } catch (error: any) {
          console.error(error);
          
          // Send metrics
          if (error.name === "BootstrapError") {
            increment(BOOTSTRAP_ERROR);
          } else if (error.name === "PlatformNotSupportedError") {
            increment(PLATFORM_NOT_SUPPORTED_ERROR);
          }
        }
      };
  
      initialize();
  
      if (loadingState === LoadingState.LOADING) {
        setTimeout(() => {
          setLoadingState(LoadingState.PENDING_COMPLETION);
          setTimeout(
            () => setLoadingState(LoadingState.FINISHED),
            LOADING_FADE_TIME_MS + 50
          );
        }, MINIMUM_LOADING_TIME_MS);
      }
    }, [apiToken, initializeCameraKit, loadingState]);
  
    return (
      <>
        {loadingState !== LoadingState.FINISHED ? (
          <Loading/>
        ) : (
          <></>
        )}
        {cameraKit && session && measurement ? (
          <CameraKitMasterContext.Provider
            value={{
              cameraKit,
              session,
              lenses,
              measurement,
              versions,
              canvas: session.output["live"],
            }}
          >
            {children}
          </CameraKitMasterContext.Provider>
        ) : (
          <></>
        )}
      </>
    );
  };