import { CSSProperties, FC} from 'react';
import { addTimerSinceStart, increment } from '../../utils/metrics/graphene';

const ShareButtonStyle: CSSProperties =
{
    position: "absolute",
    width: 32,
    margin: 3,
    right: 0
}

const ShareBackgroundButtonStyle: CSSProperties =
{
    background: "black",
    position: "absolute",
    right: 0,
    width: 38,
    height: 38,
    borderRadius: 25,
    opacity: 0.2
}

const ShareButtonContainerStyle: CSSProperties = {
  position: "absolute",
  margin: "1rem",
  right: 0
}

const SHARE_PREFIX = "share";
const SHARE_SUCCESS = `${SHARE_PREFIX}.success`;
const SHARE_ERROR = `${SHARE_PREFIX}.error`;
const SHARE_API_UNAVAILABLE = `${SHARE_PREFIX}.unavailable`;
const SHARE_TIME_ELAPSED = `${SHARE_PREFIX}.time_elapsed`;

const ShareButton: FC = () => {
  const handleShare = () => {
    addTimerSinceStart(SHARE_TIME_ELAPSED);
    if (navigator.share) {
        navigator.share({
            title: 'Chantilly | Menu',
            url: 'chantilly.snapar.com',
        })
        // TODO add telemetry trace here
        .then(() => {
          console.log('Share action successful');
          increment(SHARE_SUCCESS);
        })
        .catch((error) => {
          console.error('Error sharing: ', error);
          increment(SHARE_ERROR);
        });
    } else {
        console.log('Web Share API is not supported on this browser.');
        increment(SHARE_API_UNAVAILABLE);
    }
  };

  return (
    <div style={ShareButtonContainerStyle} onClick={handleShare}>
        <img style={ShareButtonStyle} onClick={handleShare} src={require("../../icons/share.png")}/>
        <div style={ShareBackgroundButtonStyle}/>
    </div>
  );
};

export default ShareButton;