import { Spinner } from "@snapchat/snap-design-system";
import './Loading.css';

const Loading: React.FC = () => {
  return (
    <div className="spinner-container">
        <Spinner/>
    </div>
  );
};


export default Loading;