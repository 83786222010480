import { FC, CSSProperties } from 'react';
import { addTimerSinceStart, increment } from '../../utils/metrics/graphene';

const DownloadButtonStyle: CSSProperties =
{
    margin: "0 3vh 3vh 3vh",
    borderRadius: 100,
    border: "none",
    fontFamily: "Avenir Next,Helvetica,sans-serif",
    padding: "8px 16px",
    fontWeight: 500,
    background: "white",
    color: "black",
    fontSize: 14,
    lineHeight: "16px"
}

const DOWNLOAD_PREFIX = "download"
const DOWNLOAD_BUTTON_CLICK = `${DOWNLOAD_PREFIX}.click`;
const DOWNLOAD_CLICK_ANDROID = `${DOWNLOAD_PREFIX}.android`;
const DOWNLOAD_CLICK_IOS = `${DOWNLOAD_PREFIX}.ios`;
const DOWNLOAD_CLICK_TIME_ELAPSED = `${DOWNLOAD_PREFIX}.time_elapsed`;

const DownloadButton: FC = () => {
    const detectPlatformAndRedirect = () => {
        if (/android/i.test(navigator.userAgent)) {
            // Android
            increment(DOWNLOAD_BUTTON_CLICK);
            increment(DOWNLOAD_CLICK_ANDROID);
            addTimerSinceStart(DOWNLOAD_CLICK_TIME_ELAPSED);
            window.location.href = 'https://play.google.com/store/apps/details?id=com.snapchat.android';
        } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            // iOS
            increment(DOWNLOAD_BUTTON_CLICK);
            increment(DOWNLOAD_CLICK_IOS);
            addTimerSinceStart(DOWNLOAD_CLICK_TIME_ELAPSED);
            window.location.href = 'https://apps.apple.com/fr/app/snapchat/id447188370';
        } else {
            console.log("Non-mobile platform detected.")
        }
    };

    return (
        <button onClick={detectPlatformAndRedirect} style={DownloadButtonStyle}>Download Snapchat</button>
    );
};

export default DownloadButton;
