import React, { FC, useEffect, useState } from "react";
import ShareButton from "./components/ShareButton/ShareButton";
import DownloadButton from "./components/DownloadButton/DownloadButton";
import { openSnapchat } from "./utils/redirect";
import { CameraKitContext } from "./contexts/CameraKitContext";
import { CameraSourceContext } from "./contexts/CameraSourceContext";
import Player from "./components/Player/Player";
import { addTimerSince, increment, initializeGraphene, setStartTime } from "./utils/metrics/graphene";

// Set apiToken with an API token for the desired app. (https://kit.snapchat.com/manage)
const apiToken = process.env.REACT_APP_CK_API_TOKEN
const defaultLensGroup = process.env.REACT_APP_CK_LENS_GROUP_ID; // Chantilly lens group

const App: FC = () => {

  const startMountTime = new Date();
  const isMobile  = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  openSnapchat();
  
  useEffect(() => {
    initializeGraphene();

    const onPageLoad = () => {
      addTimerSince('page_load.duration', startMountTime);
      increment('page_load.finished');
      setStartTime(new Date());
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <div>
      { isMobile &&
          <div className="container">
            <ShareButton />
            <div className="canvas-container">
            <div className="footer">
              <img className="logo" src={require("./icons/logo.png")} />
                <DownloadButton/>
              </div>
              <CameraKitContext
                apiToken={apiToken!}
                lensGroupId={defaultLensGroup!}
                shouldUseWorker={true}
              >
                <CameraSourceContext
                  initialDeviceId={undefined}
                  fpsLimit={undefined}
                >
                  <Player />
                </CameraSourceContext>
              </CameraKitContext>
            </div>
          </div>
      }
      { !isMobile &&
        <div className="non-mobile-message">
          Please open the Web AR player on a mobile device.
        </div>
      }
    </div>
  );
}

export default App;

