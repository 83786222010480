import { useEffect, useRef } from 'react';
import { useCameraKit } from '../../hooks/useCameraKit';

const Player: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const { canvas } = useCameraKit();

  useEffect(() => {
    canvasRef.current?.replaceWith(canvas);
  }, [canvas]);

  return (
    <div className="canvas-container" ref={canvasContainerRef}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default Player;
