import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCameraKit } from "../hooks/useCameraKit";
import {
  CameraKitSource,
  Transform2D,
  createMediaStreamSource,
} from "@snap/camera-kit";
import { increment } from "../utils/metrics/graphene";

export interface CameraInfo {
  deviceId: string;
  label: string;
}

interface CameraSourceState {
  devices: CameraInfo[] | null;
  selectedDeviceId: string;
  source?: CameraKitSource;
  setDevice: (options: DeviceOptions) => Promise<void>;
  isLoading: boolean;
  mediaStream?: MediaStream;
  currentDimension: Dimension;
  maxDimension: number;
}

interface CameraSourceProps {
  initialDeviceId?: string;
  fpsLimit?: number | undefined;
  children?: ReactNode;
}

interface DimensionOptions {
  label: string;
  width: number;
  height: number;
}

interface DeviceOptions {
  deviceId?: string;
  dimension?: Dimension;
  facingMode?: "environment" | "user";
}

export const ALLOWED_DIMENSIONS: Record<number, DimensionOptions> = {
  2160: {
    label: "4K",
    width: 3840,
    height: 2160,
  },
  1440: {
    label: "1440p",
    width: 2560,
    height: 1440,
  },
  1080: {
    label: "1080p",
    width: 1920,
    height: 1080,
  },
  720: {
    label: "720p",
    width: 1280,
    height: 720,
  },
  480: {
    label: "480p",
    width: 854,
    height: 480,
  },
  360: {
    label: "360p",
    width: 640,
    height: 360,
  },
} as const;

type Dimension = keyof typeof ALLOWED_DIMENSIONS;

export enum FacingMode {
  ENVIRONMENT = "environment",
  USER = "user",
}

export const CameraSourceMasterContext = createContext<
  CameraSourceState | undefined
>(undefined);

export const CameraSourceContext: React.FC<CameraSourceProps> = ({
  initialDeviceId,
  fpsLimit,
  children,
}) => {
  const isMounted = useRef<boolean>(false);
  const { session } = useCameraKit();
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const [source, setSource] = useState<CameraKitSource>();
  const [devices, setDevices] = useState<CameraInfo[] | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDimension, setCurrentDimension] = useState<Dimension>(720);
  const [maxDimension, setMaxDimension] = useState<number>(0);
  const [currentFacingMode, setCurrentFacingMode] = useState<FacingMode>(
    FacingMode.ENVIRONMENT
  );

  const CAMERA_PERMISSION_PREFIX = "camera_permission";

  const setDevice = useCallback(
    async ({
      deviceId = selectedDeviceId,
      dimension = currentDimension,
      facingMode = currentFacingMode,
    }: DeviceOptions) => {
      if (isLoading) return;

      setIsLoading(true);

      const { width, height } = ALLOWED_DIMENSIONS[dimension];

      try {
        if (mediaStream) {
          mediaStream.getVideoTracks()[0].stop();
        }

        // Send camera permission prompt event to telemetry
        increment(`${CAMERA_PERMISSION_PREFIX}.prompt`);
        const newMediaStream = await navigator.mediaDevices
          .getUserMedia({
            video: {
              width,
              height,
              facingMode,
            },
            audio: false,
          })
          .then((stream) => {
            increment(`${CAMERA_PERMISSION_PREFIX}.granted`);
            return stream;
          });

        const track = newMediaStream.getVideoTracks()[0];
        const source = createMediaStreamSource(newMediaStream, {
          cameraType: facingMode,
          fpsLimit,
        });

        await session.setSource(source);

        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const isPortrait = window.matchMedia("(orientation: portrait)").matches;

        let renderWidth = width;
        let renderHeight = height;

        if (isMobile && isPortrait && width > height) {
          renderWidth = height;
          renderHeight = width;
        }

        await source.setRenderSize(renderWidth, renderHeight);

        if (facingMode === "user") {
          source.setTransform(Transform2D.MirrorX);
        } else {
          source.setTransform(Transform2D.Identity);
        }

        setMediaStream(newMediaStream);
        setSource(source);
        setCurrentDimension(dimension);
        setSelectedDeviceId(track.getSettings().deviceId ?? "");
        setMaxDimension(((track.getCapabilities()?.width?.max ?? 0) * 9) / 16);
      } catch (error: any) {
        console.error(error);
        if(error.message.includes("Permission denied")) {
          increment(`${CAMERA_PERMISSION_PREFIX}.denied`);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      selectedDeviceId,
      currentDimension,
      currentFacingMode,
      isLoading,
      session,
      mediaStream,
      fpsLimit,
    ]
  );

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    async function initialize() {
      await setDevice({ deviceId: initialDeviceId });

      const devices = await navigator.mediaDevices.enumerateDevices();

      const cameraDevices = devices
        .filter((device) => device.kind === "videoinput")
        .map((device) => ({ deviceId: device.deviceId, label: device.label }));

      setDevices(cameraDevices);
    }

    initialize();
  }, [initialDeviceId, setDevice]);

  return (
    <CameraSourceMasterContext.Provider
      value={{
        devices,
        source,
        selectedDeviceId,
        setDevice,
        isLoading,
        mediaStream,
        currentDimension,
        maxDimension,
      }}
    >
      {children}
    </CameraSourceMasterContext.Provider>
  );
};
