/**
 * Graphene metrics is meant to handle metrics on the client.
 */
import Graphene, { PARTITION } from "@snapchat/graphene";
import getWebConfig from "@snapchat/graphene/lib/config/web";
import BrowserNetworkHandler from "@snapchat/graphene/lib/network/BrowserNetworkHandler";
const env = process.env.NODE_ENV;
const GRAPHENE_ENABLED = env === "production" || env === "staging" || env === "development";
const version = "1.0.1";
const flavor = "production";
// Start time here is set when the page finishes loading
var _startTime;
// initialize with necessary props
export const initializeGraphene = () => {
    // Graphene is not currently enabled for HTTP, so we do not initialize it in development env
    if (!GRAPHENE_ENABLED) {
        console.log("graphene initialization, skipping");
    }
    else {
        // initialize with necessary props
        Graphene.initialize({
            networkHandler: new BrowserNetworkHandler(getWebConfig({
                partitionName: PARTITION.WEB_AR_PLAYER,
                flavor: flavor,
            })),
        });
        // Sending a heart beat counter event every minute
        Graphene.sendHeartBeatCounter(version);
        sendEnvironmentData();
    }
};
export const setStartTime = (startTime) => {
    _startTime = startTime;
};
export const getStartTime = () => {
    return _startTime;
};
const ENVIRONMENT_PREFIX = "env";
const sendEnvironmentData = () => {
    const dimensions = {
        language: navigator.language
    };
    if (/android/i.test(navigator.userAgent)) {
        // Android
        Graphene.increment({
            metricsName: `${ENVIRONMENT_PREFIX}.android`,
            dimensions: dimensions
        });
    }
    else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        // iOS
        Graphene.increment({
            metricsName: `${ENVIRONMENT_PREFIX}.ios`,
            dimensions: dimensions
        });
    }
};
export const addTimer = (metricsName, milliSec) => {
    if (!GRAPHENE_ENABLED) {
        console.log(`graphene add timer: ${metricsName}, ${milliSec}ms`);
    }
    else {
        Graphene.addTimer({
            metricsName,
            milliSec,
        });
    }
};
export const addTimerSince = (metricsName, startDate) => {
    const endDate = new Date();
    const milliSec = endDate.valueOf() - startDate.valueOf();
    if (!GRAPHENE_ENABLED) {
        console.log(`metrics client add timer: ${metricsName}, ${milliSec}ms`);
    }
    else {
        Graphene.addTimer({
            metricsName,
            milliSec,
        });
    }
};
export const addTimerSinceStart = (metricsName) => {
    addTimerSince(metricsName, _startTime);
};
export const addHistogram = (metricsName, value) => {
    if (!GRAPHENE_ENABLED) {
        console.log(`graphene add histogram: ${metricsName}, ${value} value`);
    }
    else {
        Graphene.addHistogram({
            metricsName,
            value,
        });
    }
};
export const increment = (metricsName) => {
    if (!GRAPHENE_ENABLED) {
        console.log(`graphene increment counter '${metricsName}'`);
    }
    else {
        Graphene.increment({
            metricsName,
        });
    }
};
